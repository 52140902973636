import React, { useEffect } from "react"
import { Link } from "gatsby"
import axios from "axios"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const Eco = () => {

  const getIp = async () => {
    const ipField = document.getElementById("ip");
    ipField.value = "Loading...";
    const resp = await axios.get(`https://cltkzgopqe.execute-api.us-east-1.amazonaws.com/prod`);
    ipField.value = resp.data.body;
  }

    // Check if server up on load
    useEffect(() => {
      getIp();
    }, []);

  const startServer = () => axios.post(`https://cltkzgopqe.execute-api.us-east-1.amazonaws.com/prod`, { action:"start" });
  const stopServer = () => axios.post(`https://cltkzgopqe.execute-api.us-east-1.amazonaws.com/prod`, { action:"stop" });
  return (
    <main style={pageStyles}>
      <title>Eco</title>
      <h1 style={headingStyles}>Eco Server</h1>
      <p style={paragraphStyles}>
        Start the Eco server remotely
        <br />
        <br />
        <button onClick={startServer}>Start</button>
        <button onClick={stopServer} style={{"marginLeft": "10px"}}>Stop</button>
        <br />
        <br />
        <input id="ip"></input>
        <button onClick={getIp}>Get IP</button>
        <br />
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </main>
  )
}

export default Eco
